
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: '404',
})

export default class extends Vue {
  private handleClickHome() {
    this.$router.push('/');
  }
}
